import React from 'react';
import { safetyRating, fullCarName } from '../../helpers/carSelectors';

export const RatingSchema = ({ car }) => (
  <script type="application/ld+json">
    {JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Car',
      rating: {
        '@type': 'Rating',
        bestRating: '100',
        worstRating: '0',
        ratingValue: safetyRating(car),
      },
      name: fullCarName(car),
    })}
  </script>
);
