import React from 'react';
import PropTypes from 'prop-types';
import { SVG, BaseLine, Text } from './CarBadge.styles';
import { safetyRating } from 'helpers/carSelectors';

export const CarBadge = ({ car, small }) => {
  return <SVG small={small} viewBox={`0 0 100 100`}>
      <BaseLine
        d="m 0 0 l 0 100 l 100 0 l 0 -100 l -100 0"
      />
      <Text
        fontSize=".9em"
        textAnchor="middle"
        textLength="80%"
        x="50"
        y="22"
      >
        RealSafeCars
      </Text>
      <Text
        fontSize="3.4em"
        textAnchor="middle"
        textLength={`${`${safetyRating(car)}`.length * 30}%`}
        x="50"
        y="72"
      >
        {safetyRating(car)}
      </Text>
      <Text
        fontSize=".7em"
        textAnchor="middle"
        textLength="70%"
        x="50"
        y="90"
      >
        Safety Rating
      </Text>
    </SVG>
};
