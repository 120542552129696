import styled, { css } from "styled-components";
import { ifProp, fromProp } from "ifprop";

export const SVG = styled.svg`
  max-width: 120px;
  margin: 1em;
  ${ifProp({
    small: css`
      width: 80px;
      height: 80px;
    `,
    default: css`
      width: 120px;
      height: 120px;
    `,
  })}
  display: block;
`;

export const BaseLine = styled.path`
  stroke: black;
  stroke-width: 8;
  stroke-opacity: 1;
  fill: transparent;
`;

export const Text = styled.text`
  color: black;
  font-size: ${fromProp("fontSize")};
  font-weight: 400;
  transform: translateX(1px);
`;
