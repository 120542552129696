const similarityTo = (car, otherCar) => {
  let similarityRating = 1;
  if (car.Type !== otherCar.VehicleType) similarityRating *= 0.9;
  if (car.VehicleSize !== otherCar.VehicleSize) similarityRating *= 0.93;
  if (car.Make !== otherCar.Make) similarityRating *= 0.97;
  if (car.ModelYear !== otherCar.ModelYear) similarityRating *= 0.7;
  // if (car.EstimatedPrice > otherCar.EstimatedPrice + 3000)
    // similarityRating *= 0.95;
  // if (car.EstimatedPrice > otherCar.EstimatedPrice + 10000)
    // similarityRating *= 0.8;
  // if (car.EstimatedPrice < otherCar.EstimatedPrice - 3000)
    // similarityRating *= 0.95;
  // if (car.EstimatedPrice < otherCar.EstimatedPrice - 10000)
    // similarityRating *= 0.8;
  if (car.MSRPPrice > otherCar.MSRPPrice + 7000) similarityRating *= 0.95;
  if (car.MSRPPrice > otherCar.MSRPPrice + 14000) similarityRating *= 0.9;
  if (car.MSRPPrice < otherCar.MSRPPrice - 7000) similarityRating *= 0.95;
  if (car.MSRPPrice < otherCar.MSRPPrice - 14000) similarityRating *= 0.9;
  similarityRating -= Math.abs(car.CombinedMPG - otherCar.CombinedMPG) / 40;
  similarityRating -= Math.abs(car.Widthin - otherCar.Widthin) / 100;
  similarityRating -= Math.abs(car.Lengthin - otherCar.Lengthin) / 100;
  similarityRating -= Math.abs(car.Heightin - otherCar.Heightin) / 100;
  similarityRating *= Math.abs(car.ModelYear + 1 - otherCar.ModelYear);
  return similarityRating;
};

export default (comparisonCar, otherCars) => {
  const sorted = otherCars
    .filter(otherCar => similarityTo(comparisonCar, otherCar) > 0.8)
    .sort((firstCar, secondCar) =>
      similarityTo(comparisonCar, firstCar) > similarityTo(comparisonCar, secondCar) ? -1 : 1
    );
  return sorted;
};
