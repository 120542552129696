import styled, { css } from "styled-components";
import mediaQueryBuilder from "../helpers/mediaQueryBuilder";

export const SideImage = styled.img`
  border: 1px solid grey;
  margin-bottom: -.3em;
`;

export const SideFigure = styled.figure`
  margin: .5em;
  margin-bottom: 1em;
  ${mediaQueryBuilder.maxWidthMobile(css`
    margin: auto;
    float: none;
    max-width: 95%;
  `)}
  ${mediaQueryBuilder.widthTablet(css`
    float: right;
    max-width: 400px;
  `)}
`;
