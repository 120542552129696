import React from "react";
import PropTypes from "prop-types";
import { SVG, BaseLine, Circle, Text, G } from "./CarComparisonDisplay.styles";
import { Caption } from "styles/Typography";
import { Link } from "gatsby";
import {
  shortCarName,
  fullCarName,
  safetyRating,
  vehicleLifetimeFatalityChance,
  carPath,
} from "helpers/carSelectors";

const lineHeight = 7;
const lineMargin = 10;
const fullWidth = 300;
const diagramPadding = 75;
const midPoint = 50;

const joinWithAnd = (arr) => {
  if (arr.length < 2) return arr.join();
  if (arr.length === 2) return arr.join(" and the ");
  const last = arr.pop();
  const sansLast = arr.join(", the ");
  return `${sansLast}, and the ${last}`;
};
const TextLabel = ({ height, car, range, sign, comparisonCar }) => {
  const locationOnLine = (rating) =>
    ((rating - range[0]) / (range[1] - range[0])) *
    (fullWidth - diagramPadding * 2);

  const carLocation = locationOnLine(safetyRating(car)) + diagramPadding;
  // const xLength = sign * (height * -lineHeight - lineMargin);
  const xLength = 0;
  const yLength = -1 * (height * lineHeight + lineMargin);

  const length = fullCarName(car).split("").length;
  return (
    <G comparisonCar={comparisonCar}>
      <Circle cx={carLocation} cy={midPoint} r="1" />
      <BaseLine
        d={`m ${carLocation} ${midPoint} l ${xLength} ${
          height * -1 * lineHeight - lineMargin
        } l ${-3 * sign} 0`}
      />
      <Text
        textAnchor={sign === 1 ? "end" : "start"}
        x={carLocation + xLength - 5 * sign}
        y={50 + yLength + 2}
      >
        <Link to={carPath(car)}>{shortCarName(car)}</Link>
      </Text>
    </G>
  );
};

const LeftTextLabel = ({ height, car, range, comparisonCar }) => {
  return (
    <TextLabel
      height={height}
      range={range}
      comparisonCar={comparisonCar}
      car={car}
      sign={1}
    />
  );
};

const RightTextLabel = ({ height, car, range, comparisonCar }) => {
  return (
    <TextLabel
      height={height}
      range={range}
      comparisonCar={comparisonCar}
      car={car}
      sign={-1}
    />
  );
};

export const CarComparisonDisplay = ({ comparisonCar, similarCars }) => {
  if (similarCars.length < 2) return null;
  const min = Math.min(...similarCars.map((c) => safetyRating(c)));
  const max = Math.max(...similarCars.map((c) => safetyRating(c)));
  const diff = Math.round((max - min) / 3);
  const range = [
    Math.max(Math.min(20, min), min - diff),
    Math.min(max + diff, 100),
  ];
  const midPoint = safetyRating(
    similarCars[Math.round(similarCars.length / 2)]
  );
  const lessSafeCars = similarCars.filter(
    (c) => safetyRating(c) < safetyRating(comparisonCar) - 2
  );
  const moreSafeCars = similarCars.filter(
    (c) => safetyRating(c) > safetyRating(comparisonCar) + 2
  );
  const equallySafeCars = similarCars.filter(
    (c) =>
      safetyRating(c) > safetyRating(comparisonCar) - 2 &&
      safetyRating(c) < safetyRating(comparisonCar) + 2
  );

  return (
    <figure>
      <SVG viewBox={`0 0 ${fullWidth} 50`}>
        <BaseLine
          d={`m ${diagramPadding} 50 l ${fullWidth - diagramPadding * 2} 0`}
        />
        <Text textAnchor="start" x={fullWidth - diagramPadding} y="53">
          {range[1]}
        </Text>
        <Text textAnchor="end" x={diagramPadding - 2} y="53">
          {range[0]}
        </Text>
        {similarCars
          .filter((c) => safetyRating(c) < midPoint)
          .map((car, idx) => {
            return (
              <LeftTextLabel
                comparisonCar={fullCarName(comparisonCar) === fullCarName(car)}
                height={idx}
                car={car}
                range={range}
              />
            );
          })}
        {similarCars
          .reverse()
          .filter((c) => safetyRating(c) >= midPoint)
          .map((car, idx) => {
            return (
              <RightTextLabel
                comparisonCar={fullCarName(comparisonCar) === fullCarName(car)}
                height={idx}
                car={car}
                range={range}
              />
            );
          })}
      </SVG>
      <Caption>
        <strong>Comparison to similar models.</strong>&nbsp; This plot compares
        the RSC rating of the {fullCarName(comparisonCar)} with its main
        competition. Models are plotted on a scale from {range[0]} to {range[1]}
        . The {fullCarName(comparisonCar)} is{" "}
        {lessSafeCars.length > 0
          ? `safer than the ${joinWithAnd(
              lessSafeCars
                .filter((c) => fullCarName(c) !== fullCarName(comparisonCar))
                .map((c) => fullCarName(c))
            )}`
          : ""}
        {lessSafeCars.length > 0 && moreSafeCars.length > 0 ? ` and ` : ""}
        {moreSafeCars.length > 0
          ? `less safe than the ${joinWithAnd(
              moreSafeCars
                .filter((c) => fullCarName(c) !== fullCarName(comparisonCar))
                .map((c) => fullCarName(c))
            )}`
          : ""}
        .{" "}
        {equallySafeCars.length > 1
          ? `It is about as safe as the ${joinWithAnd(
              equallySafeCars
                .filter((c) => fullCarName(c) !== fullCarName(comparisonCar))
                .map((c) => fullCarName(c))
            )}.`
          : ""}
      </Caption>
    </figure>
  );
};
