import styled, { css } from "styled-components";
import { ifProp } from "ifprop";
import mediaQueryBuilder from '../../helpers/mediaQueryBuilder';
import colors from "../../variables";

export const SVG = styled.svg`
  width: 100%;
  max-width: 100%;
  margin: auto;
  height: 250px;
  display: block;
  ${mediaQueryBuilder.maxWidthMobile(css`
     transform: scale(1.8);
  `)}
`;

export const BaseLine = styled.path`
  stroke: black;
  stroke-width: 1;
  stroke-opacity: 1;
  fill: transparent;
`;

export const Circle = styled.circle`
  stroke: black;
  stroke-width: 1;
  stroke-opacity: 1;
`;

export const Text = styled.text`
  color: black;
  font-size: 8px;
  transform: translateX(1px);
  a {
    font-size: 6px;
    transform: rotate(45);
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const G = styled.g`
  ${ifProp({
    comparisonCar: css`
      text {
        a {
          fill: ${colors.primaryColorLight};
          font-weight: bold;
        }
      }
    `,
  })}
`;
