import React from "react";
import { SharedLayout } from "../layouts/SharedLayout";
import { StyledH1, StyledH2, StyledH3, P, Caption } from "styles/Typography";
import {
  StyledSection,
  SeparatedSection,
  Row,
  TwoColumns,
} from "styles/Containers";
import { SideFigure, SideImage } from "styles/Images";
import { CarBadge } from "components/CarBadge";
import { CarComparisonDisplay } from "components/CarComparisonDisplay";
import rankBySimilarity from "helpers/carSimilarityRanker";
import variables from "../variables";
import { RatingSchema } from "components/RatingSchema";
import { CarSearch } from "components/CarSearch";
import {
  AEBDifferential,
  carSafetyAdjective,
  carSafetyAdjectivePolarity,
  carSafetyColor,
  conversationalCarType,
  fullCarName,
  picturedCarName,
  safetyRating,
  vehicleLifetimeFatalityChance,
  vehicleLifetimeInjuryChance,
} from "helpers/carSelectors";

const SingleCarPage = ({ data, pageContext }) => {
  const car = data.singleCar.nodes[0];
  const allCarData = data.allCarData.nodes;
  const youtubeCode =
    car.youtubelink && car.youtubelink.match(/([a-zA-Z\d]+$)/)[0];

  const similarCars = rankBySimilarity(car, data.similarCars.nodes)
    .reduce(
      (accum, car) =>
        accum.some((c) => c.FormalModelName === car.FormalModelName)
          ? accum
          : [...accum, car],
      []
    )
    .slice(0, 12)
    .sort((a, b) => {
      if (a.CoalescedProbDeath === b.CoalescedProbDeath) {
        if (fullCarName(a) === fullCarName(car)) return 1;
        if (fullCarName(b) === fullCarName(car)) return -1;
        return 1;
      }
      return a.CoalescedProbDeath < b.CoalescedProbDeath ? 1 : -1;
    });

  const lessSafeCars = similarCars.filter(
    (c) => safetyRating(c) < safetyRating(car) - 2
  );
  const moreSafeCars = similarCars.filter(
    (c) => safetyRating(c) > safetyRating(car) + 2
  );
  const equallySafeCars = similarCars.filter(
    (c) =>
      safetyRating(c) > safetyRating(car) - 2 &&
      safetyRating(c) < safetyRating(car) + 2
  );

  let comparisonDescription = "";
  let safetyPolarity = carSafetyAdjectivePolarity(car);
  const substantialPortion = Math.round(similarCars.length / 2) - 1 || 1;

  if (lessSafeCars.length > substantialPortion) {
    const comparisonVerb = safetyPolarity === "positive" ? "And" : "But";
    comparisonDescription = (
      <>
        {comparisonVerb} it is{" "}
        <span style={{ color: variables.safe }}>safer</span> than many
        comparable models.
      </>
    );
  } else if (moreSafeCars.length > substantialPortion) {
    const comparisonVerb = safetyPolarity === "positive" ? "But" : "And";
    comparisonDescription = (
      <>
        And it is <span style={{ color: variables.unsafe }}>less safe</span>{" "}
        than many comparable cars.
      </>
    );
  } else {
    const comparisonVerb = safetyPolarity === "positive" ? "But" : "And";
    comparisonDescription = (
      <>
        {comparisonVerb} it is about{" "}
        <span
          style={{
            color: variables.average,
          }}
        >
          as safe
        </span>{" "}
        as many comparable models.
      </>
    );
  }
  return (
    <SharedLayout pageContext={pageContext}>
      <RatingSchema car={car} />
      <StyledH1>
        <Row center middle>
          {fullCarName(car)}&nbsp;&nbsp;
          <span style={{ fontSize: ".85rem" }}>
            <CarBadge small car={car} />
          </span>
        </Row>
      </StyledH1>
      <StyledH3>
        #{car.YearTypeRank} of {car.ModelYear} {car.CarTypeTeoalida}s
        <br />
        <br />#{car.YearRank} of all {car.ModelYear} vehicles
      </StyledH3>
      <br />
      <P>
        {car.PictureName && (
          <SideFigure>
            <SideImage src={`/assets/${car.PictureName}`} />
            {car.ModelYear !== car.PictureModelYear && (
              <Caption style={{ textAlign: "right" }}>
                Pictured: {picturedCarName(car)}
              </Caption>
            )}
          </SideFigure>
        )}
        {car.FullDescription}
      </P>
      <SeparatedSection>
        <figure>
          <Row center>
            <StyledH2>
              The {fullCarName(car)} is{" "}
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: carSafetyColor(car),
                }}
              >
                {carSafetyAdjective(car)}
              </span>{" "}
              compared to all vehicles.
            </StyledH2>
            <img
              src={`/assets/box_ratings/BoxRating_VerySmall_${safetyRating(
                car
              )}.jpg`}
            />
          </Row>
          <Caption>
            <strong>Comparison to all vehicles.</strong> &nbsp;
            {car.HistogramDescription}
          </Caption>
        </figure>
      </SeparatedSection>
      <P>
        {" "}
        We estimate that the {fullCarName(car)} has a vehicle lifetime fatality
        chance of {vehicleLifetimeFatalityChance(car)} and a vehicle lifetime
        injury chance of {vehicleLifetimeInjuryChance(car)}, meaning the average
        driver has a {vehicleLifetimeFatalityChance(car)} chance of dying in an
        accident if they drive the car under regular conditions for fifteen
        years, and a {vehicleLifetimeInjuryChance(car)} chance of being injured.
      </P>
      <P>
        Compared to the average vehicle, the {fullCarName(car)} is{" "}
        {car.Comparison_Num_All_Veh.replace("-", "")}%{" "}
        {car.Comparison_Text_All_Veh} to result in a fatal accident. Compared to
        other {conversationalCarType(car, { plural: true })}, the {fullCarName(car)} is{" "}
        {car.Comparison_Num_Same_Type.replace("-", "")}%{" "}
        {car.Comparison_Text_Same_Type} to result in a fatal accident.
      </P>
      {AEBDifferential(car) && (
        <P>
          {fullCarName(car)} offers AEB optionally. With AEB, the vehicle
          lifetime fatality chance drops by {AEBDifferential(car)}%.
        </P>
      )}
      {youtubeCode && (
        <Row center>
          <iframe
            id="ytplayer"
            type="text/html"
            width="640"
            height="360"
            src={`https://www.youtube.com/embed/${youtubeCode}`}
            frameborder="0"
          />
        </Row>
      )}
      {car.SafetyDescFull_Par_1 && <P>{car.SafetyDescFull_Par_1}</P>}
      {car.SafetyDescFull_Par_2 && <P>{car.SafetyDescFull_Par_2}</P>}
      <SeparatedSection>
        <StyledH2>{comparisonDescription}</StyledH2>
        <CarComparisonDisplay comparisonCar={car} similarCars={similarCars} />
      </SeparatedSection>
      <StyledSection>
        <StyledH3>Find another model</StyledH3>
        <CarSearch carSearchData={allCarData} />
      </StyledSection>
    </SharedLayout>
  );
};

export default SingleCarPage;

export const query = graphql`
  query SingleCar(
    $formalMakeName: String!
    $formalModelName: String!
    $modelYear: Date!
    $similarYearMin: Date!
    $similarYearMax: Date!
    $similarWidthinMin: Float!
    $similarWidthinMax: Float!
    $similarHeightinMin: Float!
    $similarHeightinMax: Float!
    $similarMSRPBaseMin: Int!
    $similarMSRPBaseMax: Int!
  ) {
    singleCar: allCarData(
      filter: {
        FormalMakeName: { eq: $formalMakeName }
        FormalModelName: { eq: $formalModelName }
        ModelYear: { eq: $modelYear }
      }
    ) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedProbInjury
        ProbDeathNOTEquippedWithAEB
        ProbDeathIfEquippedWithAEB
        VehicleSizeCategory
        CarTypeTeoalida
        PictureName
        youtubelink
        YouTubeModelYear
        FullDescription
        CoalescedRscRating
        CombinedMPG
        Widthin
        Lengthin
        Heightin
        MSRPBase
        ThirdRowAvailable
        SafetyDescFull_Par_1
        SafetyDescFull_Par_2
        HistogramDescription
        Comparison_Text_All_Veh
        Comparison_Text_Same_Type
        Comparison_Num_All_Veh
        Comparison_Num_Same_Type
        PictureModelYear
        YearRank
        YearTypeRank
      }
    }
    similarCars: allCarData(
      filter: {
        ModelYear: { gt: $similarYearMin, lt: $similarYearMax }
        Widthin: { gt: $similarWidthinMin, lt: $similarWidthinMax }
        Heightin: { gt: $similarHeightinMin, lt: $similarHeightinMax }
        MSRPBase: { gt: $similarMSRPBaseMin, lt: $similarMSRPBaseMax }
      }
    ) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedProbInjury
        ProbDeathNOTEquippedWithAEB
        ProbDeathIfEquippedWithAEB
        VehicleSizeCategory
        PictureName
        youtubelink
        YouTubeModelYear
        FullDescription
        CoalescedRscRating
        Widthin
        Lengthin
        Heightin
        MSRPBase
        ThirdRowAvailable
        CombinedMPG
      }
    }
    allCarData(sort: { fields: CoalescedProbDeath }) {
      nodes {
        CarTypeTeoalida
        FormalModelName
        FormalMakeName
        ModelYear
        CoalescedProbDeath
        CoalescedRscRating
        VehicleSizeCategory
      }
    }
  }
`;
